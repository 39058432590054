import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import 'amfe-flexible'
import "@/assets/text.css";
import './assets/reset.min.css'
import App from "./App.vue";
// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
// register Swiper custom elements
register();

Vue.use(ElementUI);

new Vue({
  el: "#app",
  render: (h) => h(App),
});
