<template>
  <div class="app">
    <div class="banner oneBanner">
      <div class="oneBanner_left">
        <el-image
          style="width: 100%; height: 100%"
          :src="require('./assets/images/one.png')"
          lazy
          :preview-src-list="srcList"
          @click="handleImageClick"
        ></el-image>
      </div>
      <div class="oneBanner_right">
        <div class="oneBanner_rightTop">贝·叶</div>
        <div class="oneBanner_rightBot">汉藏双语—古籍阅读器</div>
        <el-button size="small" class="button" @click="checkIfWeChatBrowser"
          >点击下载</el-button
        >
        <!-- <a href="" class="button">点击下载</a> -->
      </div>
    </div>
    <div class="wrap oneWrap">
      <div class="oneTop">
        <div class="oneTop_font">
          <div :style="{ fontWeight: '700' }">西藏文化保护</div>
          <div :style="{ fontWeight: '700', marginTop: '5px' }">
            功在当代——利在千秋
          </div>
        </div>
        <div class="decoration">
          <div class="line"></div>
          <div class="cir"></div>
        </div>
      </div>
      <div class="oneTop_fontSmall">
        全国存世藏文古籍约三分之二收藏在西藏自治区
      </div>
      <div class="oneBottom">
        <div class="oneBottom_top">
          <el-image
            style="width: 100%; height: 100%"
            :src="require('./assets/images/two.jpg')"
            lazy
            :preview-src-list="srcList"
          ></el-image>
        </div>
        <div class="oneBottom_bot">
          <div class="oneBottom_botItem">
            <div class="oneBottom_botItem_top" :style="{ fontWeight: '700' }">
              1、文化统一
            </div>
            <div class="oneBottom_botItem_bottom">
              积极响应国家政策号召，保护 西藏古文化，是国家传统文化
              保护重要的一环
            </div>
          </div>
          <div class="oneBottom_botItem">
            <div class="oneBottom_botItem_top" :style="{ fontWeight: '700' }">
              2、文化传承
            </div>
            <div class="oneBottom_botItem_bottom">
              西藏文物古籍文化价值巨大，通过数字化实现永久性
              保护，有利于藏民族悠久历史和灿烂文化的传承
            </div>
          </div>
          <div class="oneBottom_botItem">
            <div class="oneBottom_botItem_top" :style="{ fontWeight: '700' }">
              3、文化传播
            </div>
            <div class="oneBottom_botItem_bottom">
              通过现代化技术手段，让束之高阁的文物中的文字和
              思想，重新展现出原本的力量，并得以继续流传
            </div>
          </div>
          <div class="oneBottom_botItem">
            <div class="oneBottom_botItem_top" :style="{ fontWeight: '700' }">
              4、经济价值
            </div>
            <div class="oneBottom_botItem_bottom">
              文物保护将带动、培育特色文化产业发展，推动西藏
              文旅更广范围、更深层次、更高水平的融合发展
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wrap twoWrap">
      <div class="twoTop">
        <div class="decoration">
          <div class="line"></div>
          <div class="cir"></div>
        </div>
        <div class="twoTop_font">
          <div :style="{ fontWeight: '700' }">数字化保护传播推广</div>
          <div :style="{ fontWeight: '700' }" class="twoTop_fontBot">
            数字化再生性保护
          </div>
        </div>
      </div>
      <div class="twoTop_fontSmall">
        全国存世藏文古籍约三分之二收藏在西藏自治区
      </div>
      <div class="twoBottom">
        <div class="twoBottom_top">
          <el-image
            style="width: 100%; height: 100%"
            :src="require('./assets/images/three.jpg')"
            lazy
            :preview-src-list="srcList"
          ></el-image>
        </div>
        <div class="twoBottom_bot">
          受储藏环境与气候影响古籍易发生老化、霉变、污损
          古籍储藏环境潮湿易发生虫蛀现象文物、古籍损毁风
          险高，大量珍贵文献损坏，难以修复
        </div>
      </div>
    </div>

    <div class="banner twoBanner">
      通过对古籍数字化扫描，一方面使古籍免受阅览带来的伤害另一方面使读者全面了解古籍的文献信息
    </div>

    <div class="banner fourBanner">
      <div class="fourTop">
        <div class="fourTop_font">
          <div :style="{ fontWeight: '700', marginBottom: '5px' }">
            西藏自治区文化厅
          </div>
          <div :style="{ fontWeight: '700', marginBottom: '5px' }">
            官方合作
          </div>
          <div class="fourTop_fontSmall">
            同自治区非物质文化遗产保护中心合作
          </div>
        </div>
        <div class="decoration">
          <div class="line"></div>
          <div class="cir"></div>
        </div>
      </div>
      <div class="fourMid">古籍数字化与阅读器开发</div>

      <div class="fourBot">
        <div class="fourBot_top">
          <el-image
            style="width: 100%; height: 100%"
            :src="require('./assets/images/four.jpg')"
            lazy
            :preview-src-list="srcList"
          ></el-image>
        </div>
        <div class="fourBot_bot">
          同西藏自治区文化厅官方合作积极响应国家政策号召，保护西藏古文化。通过现代化技术手段，让束之高阁的文物中的文字和思想重新展现出原本的力量，实现永久性的保护，有利于藏民族和西藏悠久历史和灿烂文化的传承
        </div>
      </div>
    </div>

    <div class="banner threeBanner">
      <div class="threeTop">
        <div class="decoration">
          <div class="line"></div>
          <div class="cir"></div>
        </div>
        <div class="threeTop_font">
          <div :style="{ fontWeight: '700', marginBottom: '5px' }">
            古籍阅读器双语言
          </div>
          <div :style="{ fontWeight: '700', marginBottom: '5px' }">
            界面展示
          </div>
          <div class="threeTop_fontSmall">阅读器部分功能界面汉语藏展示对比</div>
        </div>
      </div>

      <el-carousel indicator-position="none" :autoplay="false" arrow="always">
        <el-carousel-item>
          <div class="threeBot">
            <div class="threeBot_top">
              <div class="threeBot_topLeft">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="require('./assets/images/shouyech.png')"
                  :preview-src-list="srcList"
                ></el-image>
              </div>
              <div class="threeBot_right">中文展示</div>
            </div>
            <div class="threeBot_bot">
              <div class="threeBot_botLeft">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="require('./assets/images/zangyu.png')"
                ></el-image>
              </div>
              <div class="threeBot_botRight">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="require('./assets/images/shouyezang.png')"
                  :preview-src-list="srcList"
                ></el-image>
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="threeBot">
            <div class="threeBot_top">
              <div class="threeBot_topLeft">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="require('./assets/images/shujiach.png')"
                  :preview-src-list="srcList"
                ></el-image>
              </div>
              <div class="threeBot_right">中文展示</div>
            </div>
            <div class="threeBot_bot">
              <div class="threeBot_botLeft">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="require('./assets/images/zangyu.png')"
                ></el-image>
              </div>
              <div class="threeBot_botRight">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="require('./assets/images/shujiazang.png')"
                  :preview-src-list="srcList"
                ></el-image>
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="threeBot">
            <div class="threeBot_top">
              <div class="threeBot_topLeft">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="require('./assets/images/wodech.png')"
                  :preview-src-list="srcList"
                ></el-image>
              </div>
              <div class="threeBot_right">中文展示</div>
            </div>
            <div class="threeBot_bot">
              <div class="threeBot_botLeft">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="require('./assets/images/zangyu.png')"
                ></el-image>
              </div>
              <div class="threeBot_botRight">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="require('./assets/images/wodezang.png')"
                  :preview-src-list="srcList"
                ></el-image>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="banner bottom">
      <div class="bottomTop">
        <div class="bottomTop_left">
          <div class="one">用产品演绎文化·用技术讲述历史</div>
          <div class="info">中感技术（北京）集团有限公司</div>
          <div class="info">邮箱: xxxxxx@xxxx.com</div>
          <div class="info">联系方式: 131xxxxxxxx</div>
          <div class="info">地址: 北京市丰台区搜宝商务中心</div>
        </div>
        <div class="bottomTop_right">
          <!-- <div class="erweimaBox">
            <div class="erweima">
              <el-image
                style="width: 90%; height: 90%; display: inline-block"
                :src="require('./assets/images/erweimatwo.jpg')"
                lazy
                :preview-src-list="srcList"
              ></el-image>
            </div>
            <div class="erweimaxia">安卓下载</div>
          </div> -->
          <div class="erweimaBox">
            <div class="erweima">
              <el-image
                style="width: 90%; height: 90%"
                :src="require('./assets/images/erweimaone.jpg')"
                lazy
                :preview-src-list="srcList"
              ></el-image>
            </div>
            <div class="erweimaxia">公众号</div>
          </div>
        </div>
      </div>
      <div class="bottomBot">
        版权所有: 中感技术（北京）集团有限公司 | 备案号: 京ICP备2021022154号
      </div>
    </div>

    <div v-if="isWeChatBrowser" class="fullscreen-image" @click="closeImg">
      <img src="./assets/images/tishi.jpg" @click="showWeChatImage = false" />
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
export default {
  name: "App",
  data() {
    return {
      isIOS: false,
      isWeChatBrowser: false,
      srcList: [
        require("./assets/images/one.png"),
        require("./assets/images/two.jpg"),
        require("./assets/images/three.jpg"),
        require("./assets/images/four.jpg"),
        require("./assets/images/shouyech.png"),
        require("./assets/images/shouyezang.png"),
        require("./assets/images/shujiach.png"),
        require("./assets/images/shujiazang.png"),
        require("./assets/images/wodech.png"),
        require("./assets/images/wodezang.png"),
        require("./assets/images/erweimatwo.jpg"),
        require("./assets/images/erweimaone.jpg"),
      ],
    };
  },
  watch: {
    isWeChatBrowser(newVal) {
      this.preventScroll(newVal);
    },
  },
  beforeDestroy() {
    this.preventScroll(false); // 为了确保在组件销毁前还原页面滚动
  },
  methods: {
    checkIfWeChatBrowser() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        Message.warning({
          message: "暂不支持IOS设备",
          type: "info",
        });
        return;
      }

      // if (userAgent.indexOf("micromessenger") !== -1) {
      //   // 在微信浏览器内部
      //   this.isWeChatBrowser = true;
      // } else {
      //   this.isWeChatBrowser = false;
      //   window.open("https://beiye.cangshuo.cn/uploads/apk/guji_read.apk");
      // }

      if (userAgent.indexOf("micromessenger") !== -1) {
        // 在微信浏览器内部
        this.isWeChatBrowser = true;

        // 在微信浏览器中直接跳转到下载链接
        window.location.href =
          "https://beiye.cangshuo.cn/uploads/apk/guji_read.apk";
      } else {
        this.isWeChatBrowser = false;

        // 在非微信浏览器中创建一个<a>元素并模拟点击下载
        var downloadLink = document.createElement("a");
        downloadLink.href =
          "https://beiye.cangshuo.cn/uploads/apk/guji_read.apk";
        downloadLink.download = "guji_read.apk";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    },
    handleImageClick(event) {
      event.preventDefault();
    },

    closeImg() {
      this.isWeChatBrowser = false;
    },
    preventScroll(isPrevent) {
      if (isPrevent) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
};
</script>

<style scoped lang="less">
.app {
  font-family: "aa";

  .banner {
    width: 100vw;
    box-sizing: border-box;
  }
  .oneBanner {
    background-image: url("./assets/images/beijing.jpg");
    height: 167px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .oneBanner_left {
      width: 163px;
      height: 134px;
    }

    .oneBanner_right {
      width: 55%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .oneBanner_rightTop {
        font-size: 31px;
        color: #fcefbf;
        margin-bottom: 10px;
      }
      .oneBanner_rightBot {
        font-size: 15px;
        color: #fcefbf;
      }

      .button {
        border-radius: 5px;
        margin-top: 13px;
        color: #fcefbf;
        font-size: 15px;
        padding: 4px 11px;
        background-color: #955d45;
        border: 1px solid rgba(255, 255, 255, 0.5);
        font-family: "aa";
      }
    }
  }
  .wrap {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 85vw;
  }
  .oneWrap {
    padding: 23px 0 38px 0;
    box-sizing: border-box;

    .oneTop {
      display: flex;
      justify-content: space-between;
      .oneTop_font {
        font-size: 22px;
        // font-weight: 600;
        margin-bottom: 10px;
      }
      .decoration {
        width: 3px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .line {
          width: 1px;
          height: 40px;
          background-color: #000000;
        }
        .cir {
          width: 2px;
          height: 2px;
          background-color: #000000;
          border-radius: 50%;
        }
      }
    }

    .oneTop_fontSmall {
      color: #333333;
      font-size: 12px;
      margin-bottom: 12px;
    }

    .oneBottom {
      .oneBottom_top {
        width: 100%;
        height: 176px;
      }

      .oneBottom_bot {
        .oneBottom_botItem {
          margin-top: 15px;
          .oneBottom_botItem_top {
            font-size: 16px;
            margin-bottom: 8px;
          }

          .oneBottom_botItem_bottom {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }

  .twoWrap {
    padding: 23px 0 8px 0;
    box-sizing: border-box;

    .twoTop {
      display: flex;
      justify-content: space-between;
      .twoTop_font {
        font-size: 22px;
        // font-weight: 600;
        margin-bottom: 10px;
      }
      .decoration {
        width: 3px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .line {
          width: 1px;
          height: 40px;
          background-color: #000000;
        }
        .cir {
          width: 2px;
          height: 2px;
          background-color: #000000;
          border-radius: 50%;
        }
      }
    }

    .twoTop_font {
      display: flex;
      flex-direction: column;
      align-items: end;
      .twoTop_fontBot {
        font-size: 14px;
        color: #333333;
        margin-top: 5px;
      }
    }

    .twoTop_fontSmall {
      color: #333333;
      font-size: 12px;
      margin-bottom: 12px;
    }

    .twoBottom {
      .twoBottom_top {
        width: 100%;
        height: 176px;
      }

      .twoBottom_bot {
        font-size: 14px;
        margin-top: 16px;
        color: #666666;
        line-height: 18px;
      }
    }
  }

  .twoBanner {
    line-height: 18px;
    padding: 18px 30px;
    font-size: 14px;
    color: #000000;
    background-color: #f1f1f1;
    margin-bottom: 35px;
    // font-weight: 700;
  }

  .fourBanner {
    padding: 25px 30px;
    font-size: 14px;
    color: #000000;
    background-color: #f1f1f1;

    .fourTop {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 17px;

      .fourTop_font {
        display: flex;
        flex-direction: column;
        // align-items: end;
        font-size: 22px;
        margin-bottom: 10px;

        .fourTop_fontSmall {
          font-size: 14px;
          color: #333333;
        }
      }
      .decoration {
        width: 3px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .line {
          width: 1px;
          height: 40px;
          background-color: #000000;
        }
        .cir {
          width: 2px;
          height: 2px;
          background-color: #000000;
          border-radius: 50%;
        }
      }
    }

    .fourMid {
      margin-bottom: 15px;
      font-size: 14px;
      color: #333333;
    }

    .fourBot {
      .fourBot_top {
        width: 100%;
        height: 138px;
        margin-bottom: 15px;
        // img {
        //   width: 100%;
        //   height: 100%;
        // }
      }

      .fourBot_bot {
        line-height: 18px;
        font-size: 14px;
        color: #666666;
      }
    }
  }

  .threeBanner {
    // padding: 28px 30px 32px 30px;
    padding-top: 28px;
    font-size: 14px;
    color: #000000;

    .threeTop {
      padding: 0 30px;
      box-sizing: border-box;

      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 25px;
      .threeTop_font {
        display: flex;
        flex-direction: column;
        align-items: end;
        font-size: 22px;
        margin-bottom: 10px;

        .threeTop_fontSmall {
          font-size: 14px;
          color: #333333;
        }
      }
      .decoration {
        width: 3px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .line {
          width: 1px;
          height: 40px;
          background-color: #000000;
        }
        .cir {
          width: 2px;
          height: 2px;
          background-color: #000000;
          border-radius: 50%;
        }
      }
    }

    ::v-deep .el-carousel__arrow--left {
      left: 10px !important;
      top: 120px;
      width: 30px;
      height: 30px;
    }

    ::v-deep .el-carousel__arrow--right {
      right: 10px !important;
      top: 118px;
      width: 30px;
      height: 30px;
    }

    .threeBot {
      padding: 0 50px; /* 添加内部间距，使内容更明显 */
    }

    .threeBot {
      .threeBot_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        .threeBot_topLeft {
          width: 234px;
          height: 101px;
        }
        .threeBot_right {
          width: 50px;
          height: 46px;
          font-size: 21px;
          color: #000000;
        }
      }

      .threeBot_bot {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .threeBot_botLeft {
          width: 60px;
          height: 36px;
        }
        .threeBot_botRight {
          width: 234px;
          height: 101px;
        }
      }
    }
  }
  .bottom {
    background-color: #333333;
    padding: 19px 20px 0;
    box-sizing: border-box;
    .bottomTop {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #4f4f4f;
      padding-bottom: 8px;
      .bottomTop_left {
        color: #ffffff;
        .one {
          font-size: 12px;
          margin-bottom: 15px;
        }
        .info {
          font-size: 10px;
        }
      }

      .bottomTop_right {
        width: 44%;
        display: flex;
        justify-content: right;

        .erweimaBox {
          display: flex;
          flex-direction: column;
          align-items: center;

          .erweima {
            display: flex;
            justify-content: center;
            height: 67px;
            width: 67px;
          }
          .erweimaxia {
            font-size: 12px;
            color: #ffffff;
          }
        }
      }
    }

    .bottomBot {
      padding: 5px 0;
      font-size: 10px;
      color: #ffffff;
      padding: 0 0 20px 0;
    }
  }
  .fullscreen-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7); // 黑色背景，半透明
    display: flex;
    z-index: 1000; // 确保在最上层
  }

  .fullscreen-image img {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
  }
}
</style>
